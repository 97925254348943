let serverLoadedEvent_p = Promise.resolve(window.__INITIAL_STATE__);
// This is needed so it doesn't get compiled with the uberdata for production
if (!process.env.VUE_APP_IS_DEV) {
  serverLoadedEvent_p = import('./uberDataForTesting').then(mod => {
    return mod.uberData;
  });
}

import Vue from 'vue';
import { uicomponents } from '@goldcast/uicomponents';
Vue.use(uicomponents, {
  $trackEvent: () => {}
});
import App from './App.vue';
serverLoadedEvent_p.then(serverLoadedEvent => {
  new Vue({
    i18n: Vue.$i18n,
    render: h => h(App, { props: { serverLoadedEvent } })
  }).$mount('#appRoot');
});
