// This is needed so that we can render on server with server's timezone
// and show on client with its timezon. Check App.vue data section.
export function makeDateFormatter(timeZone) {
  let formatter = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric'
  });
  if (timeZone) {
    formatter = new Intl.DateTimeFormat('en', {
      month: 'short',
      day: 'numeric',
      timeZone
    });
  }
  return formatter;
}

export function isBefore(start, end) {
  return new Date(start).getTime() < new Date(end).getTime();
}

export function makeStartToEndDateStringifier(timeZone) {
  const dateFormatter = makeDateFormatter(timeZone);
  let timeFormatter = new Intl.DateTimeFormat('en', {
    timeStyle: 'short'
  });
  if (timeZone) {
    timeFormatter = new Intl.DateTimeFormat('en', {
      timeStyle: 'short',
      timeZone
    });
  }
  return (startDate, endDate) => {
    try {
      const startTimeString = timeFormatter.format(startDate);
      const endTimeString = timeFormatter.format(endDate);
      /* eslint-disable */
      return datesAreOnSameDay(startDate, endDate)
        ? `${dateFormatter.format(startDate)} · ${startTimeString} - ${endTimeString} ${getTimeZoneFromDate(
            startDate,
            timeZone
          )}`
        : dateFormatter.formatRange(startDate, endDate);
      /* eslint-enable */
    } catch (e) {
      console.error('Failed to parse event start and end times');
      return '';
    }
  };
}

export function datesAreOnSameDay(first, second) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export function getTimeZone(date) {
  const longTimeZone = new Date(date)
    .toLocaleTimeString('en-us', { timeZoneName: 'long' })
    .split(' ')
    .slice(2)
    .join(' ');
  return longTimeZone.match(/\b(\w)/g).join('');
}

export function getTimeZoneFromDate(date, timeZone) {
  if (!date) {
    return '';
  }
  const jsDate = new Date(date);
  let formatter = new Intl.DateTimeFormat({}, { timeZoneName: 'short' });

  if (timeZone) {
    formatter = new Intl.DateTimeFormat(
      {},
      {
        timeZoneName: 'short',
        timeZone: timeZone
      }
    );
  }

  const parts = formatter.formatToParts(jsDate);
  let timezone = '';

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part.type === 'timeZoneName') {
      timezone = part.value;
      break;
    }
  }

  return timezone;
}

export function isValidEmail(email) {
  if (!isValidString(email)) {
    return false;
  }
  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isValidCustomRegex(validationRegexStr, fieldData) {
  if (!isValidString(fieldData)) {
    return false;
  }

  const re = new RegExp(validationRegexStr);
  return re.test(String(fieldData).toLowerCase());
}

export function isValidString(value) {
  return typeof value === 'string';
}

export function isValidSelectValue(value, choices) {
  return (choices || []).some(val => val === value);
}

export function getPublicThumbnailImage(user) {
  try {
    if (!(user && user.profile_picture_url) || typeof user.profile_picture_url !== 'string') {
      return '';
    }

    const originalUrlSplit = user.profile_picture_url.split('/') || [];
    originalUrlSplit.shift();
    const joined = originalUrlSplit.join('/');

    return joined && `${getEnv('CLOUD_FRONT_URL')}user-resized/${joined}`;
  } catch (err) {
    console.error(err);
    return '';
  }
}

export function getMediumSizedImage(user, s3url) {
  try {
    if (!user.profile_picture_url) {
      return '';
    }
    const originalUrlSplit = (user.profile_picture_url && user.profile_picture_url.split('/')) || [];
    originalUrlSplit.shift();
    const joined = originalUrlSplit.join('/');
    return joined && `${s3url}/${joined}`;
  } catch (err) {
    console.error(err);
    return '';
  }
}

export const splashThemeOverwrites = {
  '--theme-primary-color': 'var(--splash-background-color)',
  '--primary-background-shade': 'var(--splash-background-shade)',
  '--primary-text-color': 'var(--splash-text-color)',
  '--font-name': 'var(--splash-font-name)'
};

export const getEnv = value => {
  const URLS = {
    CLOUD_FRONT_URL: 'https://d6d4ismr40iw.cloudfront.net/'
  };

  if (process?.env?.[value]) {
    return process.env[value];
  }

  if (typeof window !== 'undefined' && window?.configs?.[value]) {
    return window.configs[value];
  }

  return URLS[value];
};
