import Vue from 'vue';
// import { uicomponents } from "uicomponents";
// Vue.use(uicomponents);
export const ACTION_NAMES = {
  POPUP_AND_REDIRECT: 'POPUP_AND_REDIRECT',
  REDIRECT: 'REDIRECT',
  DEFAULT: 'DEFAULT',
  PRE_REGISTER_REDIRECT: 'PRE_REGISTER_REDIRECT'
};

export class Action {
  constructor(action_type, action_metadata) {
    this.action_type = action_type;
    this.action_metadata = action_metadata;
  }

  takeAction() {
    console.log('Taking action!', this.action_type, this.action_metadata);
  }
}

export class DefaultAction extends Action {
  constructor(action_metadata) {
    super(ACTION_NAMES.DEFAULT, action_metadata);
  }
}

export class RedirectAction extends Action {
  constructor(action_metadata) {
    super(ACTION_NAMES.REDIRECT, action_metadata);
  }

  takeAction() {
    if (!this.action_metadata || !this.action_metadata.url) {
      return;
    }

    window.open(this.action_metadata.url, '_self');
  }
}

export class RedirectWithPopup extends Action {
  constructor(action_metadata) {
    super(ACTION_NAMES.POPUP_AND_REDIRECT, action_metadata);
  }

  takeAction() {
    if (!this.action_metadata || !this.action_metadata.url) {
      return;
    }
    const redirectionPopupHeading = this.action_metadata.heading || '';
    const redirectionPopupContent = this.action_metadata.message || '';
    const redirectionUrl = this.action_metadata.url;
    const waitDurationBeforeRedirect = this.action_metadata.waitDuration || 6;

    const dialog = new Vue({
      template: `
        <PreRedirectionPopup
          heading="${redirectionPopupHeading}"
          message="${redirectionPopupContent}"
          url="${redirectionUrl}"
          durationToRedirect="${waitDurationBeforeRedirect}"
        ></PreRedirectionPopup>
      `
    });

    dialog.$mount();

    const wrapper = document.createElement('span');
    const vueAppHolderElement = document.getElementById('appRoot');

    if (!vueAppHolderElement) return;

    vueAppHolderElement.appendChild(wrapper);
    wrapper.appendChild(dialog.$el);

    window.setTimeout(() => {
      window.open(this.action_metadata.url, '_self');
    }, waitDurationBeforeRedirect * 1000);
  }
}

const action_map = {
  [ACTION_NAMES.REDIRECT]: RedirectAction,
  [ACTION_NAMES.POPUP_AND_REDIRECT]: RedirectWithPopup,
  [ACTION_NAMES.DEFAULT]: DefaultAction,
  [ACTION_NAMES.PRE_REGISTER_REDIRECT]: RedirectAction
};

export default function getAction(actionType) {
  return action_map[actionType] || DefaultAction;
}
